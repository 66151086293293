import "./intro.css";

import { FaAward } from "react-icons/fa";
import React from "react";
import { VscFolderLibrary } from "react-icons/vsc";
import img from '../../assets/sadiq.png'


const Intro = () => {
  return (
    <section id="about">
      <h5>Get to know</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={img} alt="Sadiq Aliyu" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small>5+ years</small>
            </article>
            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Projects</h5>
              <small>10+ Completed Projects</small>
            </article>
          </div>
          <p>
            A solution-driven Web/Software Developer adept at contributing to highly collaborative work environment, 
            finding solutions and determining customer satisfaction. Proven experience developing consumer-focused websites 
            using HTML, CSS, PHP and JavaScript. Built products for desktop and mobile app users over 5+ years, 
            meeting highest standards for web design, user experience, best practices and speed. 
            Designed and developed web applications across multiple APIs, third-party integrations and databases.
          </p>
          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default Intro;
