import media92 from "../../assets/projects/92media.png";
import electoral_hub from "../../assets/projects/electoral_hub.png";
import enegify from "../../assets/projects/enegify.png";
import energycrs from "../../assets/projects/energycrs.png";
import moriheba from "../../assets/projects/moriheba.png";
import rahde from "../../assets/projects/rahde.png";
import skyline from "../../assets/projects/skyline_int.png";
import uhm from "../../assets/projects/ultimate-health-hmo.png";

export const soloProjects = [
    {
      id: 1,
      title: "92Media Ventures",
      img: media92,
      description:
        "This project involves building a website that showcases 92Media Venture Services",
      link: "https://92mediaventures.com/",
    },
    {
      id: 2,
      title: "Energy Cross River",
      img: energycrs,
      description: "This project was build for Cross River State for its Energy Conference.",
      link: "https://energycrs.org",
    },
    {
      id: 3,
      title: "Moriheba Couture",
      img: moriheba,
      description:
        "An ecommerce store, where people can easily shop for their favorite Adire wears.",
      link: "https://morihebacouture.com",
    },
    {
      id: 4,
      title: "Enegify",
      img: enegify,
      description:
        "A startup, advertising their products",
      link: "https://enegify.tech/",
    },
    {
      id: 5,
      title: "Ultimate Health HMO",
      img: uhm,
      description:
        "A health management organization showcasing their products and services",
      link: "https://ultimatehealthhmo.com/",
    },
    {
      id: 6,
      title: "Rahde Store",
      img: rahde,
      description:
        "An ecommerce store that deals on fragrances and fashion",
      link: "https://rahdestore.com/",
    },
    {
      id: 7,
      title: "Skyline International Tourism and Hospitality ltd",
      img: skyline,
      description: "A website to manage all businnesses of SITH ltd",
      link: "https://sithltd.com"
    },
    {
      id: 8,
      title: "electoral hub",
      img: electoral_hub,
      description:
        "The Electoral Hub is an affiliate of The Initiative for Research, Innovation, Advocacy in Development (IRIAD).",
      link: "https://electoralhub.org",
    },
  ];